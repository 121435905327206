import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import withFunnelWrapper from "../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import Loader from "../../../molecules/loader/Loader";
import PollingLoader from "../../../organisms/polling-loader/PollingLoader";
import { useResult } from "../../../organisms/result/use-cases/result-use-cases";
import { getCurrentURLSearchParams } from "../../../utils/browser-features";
import * as styles from "../../Requoting.module.scss";

const Page = () => {
  const { t } = useTranslation();
  const result = useResult();
  const urlSearchParams = getCurrentURLSearchParams();
  const originalQueryParams = urlSearchParams?.toString() || "";
  const nextUrl = `/results/${result?.uuid}?${originalQueryParams}`;

  return (
    <PollingLoader
      nextUrl={nextUrl}
      statusesCallbacks={{
        created: ({ waitAndRetry }) => waitAndRetry(),
        in_progress: ({ waitAndRetry }) => waitAndRetry(),
      }}
    >
      <Loader title={t("requoting.title", { petName: result?.pet_name })} />
    </PollingLoader>
  );
};

export default withFunnelWrapper(Page, styles.requoting);

export const query = graphql`
  query ReQuotingRequestQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
